import { Alert, Card, Col } from "react-bootstrap";
import {
  useQueryResponse,
  QueryResponseProvider,
} from "../../provider/query-response/upload-tanda-tangan";
import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { SweetAlert } from "../../hope-ui/components/sweet-alert";
import FetchLoading from "../../hope-ui/components/fetch-loading";
import { useAuth } from "../../provider/core/auth";
import { initialQueryResponse } from "../../models/upload-tanda-tangan/_queryResponse";
const UploadTandaTanganList = () => {
  const nik_gtk = useAuth().currentUser?.data?.username;
  const {
    isLoadingPostUploadTandaTangan,
    isLoadingGetDetailUploadTandaTangan,
    formikUploadTandaTangan,
    detailUploadTandaTangan,
    handleGetDetailUploadTandaTangan,
    setDetailUploadTandaTangan,
  } = useQueryResponse();
  const [signatureData, setSignatureData] = useState(null); // Untuk menyimpan gambar tanda tangan
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);

  const signaturePad = useRef<any>(); // Untuk mengakses kanvas
  const clearSignature = () => {
    if (signaturePad && signaturePad.current) {
      signaturePad.current.clear();
      formikUploadTandaTangan?.setFieldValue("file", null);
    }
  };

  // Fungsi untuk menyimpan tanda tangan sebagai gambar
  const saveSignature = () => {
    if (signaturePad && signaturePad.current) {
      const dataUrl = signaturePad.current.toDataURL(); // Mengambil gambar dari kanvas
      const base64String = dataUrl; // Replace with actual Base64 string
      const fileName = "image.png";
      const fileType = "image/png";

      // Convert the Base64 string to a File
      const file = base64ToFile(base64String, fileName, fileType);

      formikUploadTandaTangan?.setFieldValue("file", file);
      formikUploadTandaTangan?.submitForm();
    }
  };

  const base64ToFile = (base64String, fileName, fileType) => {
    // Remove the base64 data prefix (if any), e.g. "data:image/png;base64,"
    const base64Data = base64String.replace(/^data:.+;base64,/, "");

    // Convert the base64 string to binary data
    const binaryData = atob(base64Data);

    // Create a Uint8Array to store the binary data
    const byteArray = new Uint8Array(binaryData.length);

    // Populate the byteArray with binary data
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob object from the byteArray
    const blob = new Blob([byteArray], { type: fileType });

    // Create a File object from the Blob
    const fileObj = new File([blob], fileName, { type: fileType });

    // Set the file state to the newly created File object
    return fileObj;
  };

  useEffect(() => {
    if (nik_gtk) handleGetDetailUploadTandaTangan(nik_gtk);
  }, [nik_gtk]);

  const checkSignature = () => {
    if (signaturePad.current) {
      if (!signaturePad.current.isEmpty()) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    saveSignature();
  }, []);

  return (
    <>
      {(isLoadingGetDetailUploadTandaTangan ||
        isLoadingPostUploadTandaTangan) && <FetchLoading />}
      {detailUploadTandaTangan && detailUploadTandaTangan.id && (
        <>
          <Card.Body>
            <Alert key={"success"} variant="success" className="fs-6 py-1 px-2">
              <i
                className="fa fa-check me-2"
                style={{
                  fontSize: 20,
                }}
              ></i>
              Anda Telah Meng-upload Tanda Tangan,{" "}
              <a
                href="javascript:;"
                onClick={() => {
                  setDetailUploadTandaTangan(
                    initialQueryResponse.detailUploadTandaTangan
                  );
                }}
                style={{
                  textDecoration: "underline",
                }}
              >
                klik disini
              </a>{" "}
              Jika Anda Ingin Meng-upload Ulang Tanda Tangan
            </Alert>
          </Card.Body>
        </>
      )}

      {(!detailUploadTandaTangan || !detailUploadTandaTangan.id) && (
        <>
          <Card.Header>
            <h6>Buat Tanda Tangan Pada Kanvas di Bawah Ini</h6>
          </Card.Header>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (checkSignature()) {
                saveSignature();
                // formikUploadTandaTangan?.handleSubmit();
              } else {
                SweetAlert({
                  icon: "warning",
                  title: "Terjadi Kesalahan",
                  text: "Kanvas Tanda Tangan Tidak Boleh Kosong",
                  allowOutsideClick: false,
                });
              }
            }}
          >
            <Card.Body className="pt-4 d-flex flex-row justify-content-center align-items-center">
              <SignatureCanvas
                ref={signaturePad}
                penColor="black"
                canvasProps={{
                  width: 250,
                  height: 250,
                  className: "signature-canvas border border-dark",
                }}
              />
            </Card.Body>
            <Card.Footer className="d-flex flex-row justify-content-end gap-3">
              <button className="btn btn-sm btn-primary">
                <i className="me-1 fa fa-floppy-disk"></i>
                Simpan
              </button>

              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={clearSignature}
              >
                <i className="me-1 fa fa-trash"></i>
                Hapus
              </button>
            </Card.Footer>
          </form>
        </>
      )}
    </>
  );
};

export const UploadTandaTanganListWrapper = () => (
  <QueryResponseProvider>
    <UploadTandaTanganList />
  </QueryResponseProvider>
);
