import { Card, Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { useLayout } from "../../layout/core/LayoutProvider";
import { useEffect } from "react";
import { UploadTandaTanganListWrapper } from "../../modules/upload-tanda-tangan";

const UploadTandaTangan = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Upload Tanda Tangan";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Upload Tanda Tangan";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <UploadTandaTanganListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UploadTandaTangan;
