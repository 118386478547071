import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";

export type FormUploadTandaTanganPayload = {
  id: string | number | null;
  nik_gtk: string | null;
  file: File | string | null;
};

export type QueryResponseContextProps<T> = {
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostUploadTandaTangan: (data: FormData) => void;
  isLoadingPostUploadTandaTangan: boolean;
  handleGetDetailUploadTandaTangan: (id: string) => void;
  isLoadingGetDetailUploadTandaTangan: boolean;
  detailUploadTandaTangan: FormUploadTandaTanganPayload;
  setDetailUploadTandaTangan: Dispatch<
    SetStateAction<FormUploadTandaTanganPayload>
  >;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  formikUploadTandaTangan?: FormikProps<FormUploadTandaTanganPayload>;
  formikUploadTandaTanganValidationSchema?: any;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  submitting: false,
  setSubmitting: () => {},
  handlePostUploadTandaTangan: () => {},
  isLoadingPostUploadTandaTangan: false,
  detailUploadTandaTangan: {
    id: null,
    nik_gtk: null,
    file: null,
  },
  setDetailUploadTandaTangan: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleGetDetailUploadTandaTangan: () => {},
  isLoadingGetDetailUploadTandaTangan: false,
};
