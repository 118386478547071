import { Card, Col, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../provider/query-response/dashboard";
import { useEffect } from "react";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import FetchLoadingOnly from "../../../hope-ui/components/fetch-loading-only";

const Total = () => {
  const {
    handleGetTotalGTK,
    isLoadingTotalGTK,
    totalGTKLaki,
    totalGTKPerempuan,
    totalSemuaGTK,
    totalGTKAkunAktif,
    totalGTKAkunTidakAktif,
    totalGTKBekerja,
    totalGTKTidakBekerja,
    totalStruktur,
    totalNonStruktur,
  } = useQueryResponse();
  useEffect(() => {
    if (handleGetTotalGTK) {
      let newState = {
        search: ``,
        size: 10,
        page: 1,
        filter: ``,
        col_header: ``,
        status_bekerja: "aktif",
        status_aktivasi: "",
      };
      const col_header = `["nik_gtk", "nama_gtk", "unit_kerja"]`;
      let query = stringifyRequestQuery(newState, "dashboard");
      handleGetTotalGTK(query, "All");
    }
  }, [handleGetTotalGTK]);
  return (
    <Col md="12" lg="12">
      <Card>
        <Card.Body>
          <Row className="gy-5">
            <Col md="12" xs="12">
              <div className="progress-widget">
                <i
                  className="fa-solid fa-person-walking-luggage"
                  style={{
                    color: "#300FF8",
                    fontSize: 50,
                  }}
                ></i>
                <div className="progress-detail">
                  <p className="mb-2">Total GTK</p>
                  {isLoadingTotalGTK ? (
                    <FetchLoadingOnly />
                  ) : (
                    <h4 className="counter">{totalGTKBekerja}</h4>
                  )}
                </div>
              </div>
            </Col>
            <Col md="6" xs="12">
              <div className="progress-widget">
                <i
                  className="fas fa-male"
                  style={{
                    color: "#300FF8",
                    fontSize: 70,
                  }}
                ></i>
                <div className="progress-detail">
                  <p className="mb-2">GTK Laki-laki</p>
                  {isLoadingTotalGTK ? (
                    <FetchLoadingOnly />
                  ) : (
                    <h4 className="counter">{totalGTKLaki}</h4>
                  )}
                </div>
              </div>
            </Col>

            <Col md="6" xs="12">
              <div className="progress-widget">
                <i
                  className="fas fa-female"
                  style={{
                    color: "#EA67AE",
                    fontSize: 70,
                  }}
                ></i>
                <div className="progress-detail">
                  <p className="mb-2">GTK Perempuan</p>
                  {isLoadingTotalGTK ? (
                    <FetchLoadingOnly />
                  ) : (
                    <h4 className="counter">{totalGTKPerempuan}</h4>
                  )}
                </div>
              </div>
            </Col>

            <Col md="6" xs="12">
              <div className="progress-widget">
                <i
                  className="fas fa-user"
                  style={{
                    color: "#DCD525",
                    fontSize: 50,
                  }}
                ></i>
                <div className="progress-detail">
                  <p className="mb-2">Akun GTK Aktif</p>
                  {isLoadingTotalGTK ? (
                    <FetchLoadingOnly />
                  ) : (
                    <h4 className="counter">{totalGTKAkunAktif}</h4>
                  )}
                </div>
              </div>
            </Col>

            <Col md="6" xs="12">
              <div className="progress-widget">
                <i
                  className="fas fa-user-slash"
                  style={{
                    color: "#300FF8",
                    fontSize: 50,
                  }}
                ></i>
                <div className="progress-detail">
                  <p className="mb-2">Akun GTK Tidak Aktif</p>
                  {isLoadingTotalGTK ? (
                    <FetchLoadingOnly />
                  ) : (
                    <h4 className="counter">{totalGTKAkunTidakAktif}</h4>
                  )}
                </div>
              </div>
            </Col>

            <Col md="6" xs="12">
              <div className="progress-widget">
                <i
                  className="fas fa-user"
                  style={{
                    color: "#32a832",
                    fontSize: 50,
                  }}
                ></i>
                <div className="progress-detail">
                  <p className="mb-2">GTK Struktur</p>
                  {isLoadingTotalGTK ? (
                    <FetchLoadingOnly />
                  ) : (
                    <h4 className="counter">{totalStruktur}</h4>
                  )}
                </div>
              </div>
            </Col>

            <Col md="6" xs="12">
              <div className="progress-widget">
                <i
                  className="fas fa-user"
                  style={{
                    color: "#a83232",
                    fontSize: 50,
                  }}
                ></i>
                <div className="progress-detail">
                  <p className="mb-2">GTK Non Struktur</p>
                  {isLoadingTotalGTK ? (
                    <FetchLoadingOnly />
                  ) : (
                    <h4 className="counter">{totalNonStruktur}</h4>
                  )}
                </div>
              </div>
            </Col>

            <Col md="4" lg="4" xs="12">
              <div className="progress-widget">
                <i
                  className="fa-solid fa-power-off"
                  style={{
                    color: "#300FF8",
                    fontSize: 50,
                  }}
                ></i>
                <div className="progress-detail">
                  <p className="mb-2">GTK Resign</p>
                  {isLoadingTotalGTK ? (
                    <FetchLoadingOnly />
                  ) : (
                    <h4 className="counter">{totalGTKTidakBekerja}</h4>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};

export const TotalWrapper = () => <Total />;
