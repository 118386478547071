import axios, { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getDetailKepegawaian = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_KEPEGAWAIAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postKepegawaian = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_KEPEGAWAIAN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteKepegawaian = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_KEPEGAWAIAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocKepegawaian = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const exportExcel = async (query: string): Promise<any> => {
  return axios({
    url: `${ApiConfig.EXPORT_EXCEL_KEPEGAWAIAN_URL}?${query}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "SIMBI Data Kepegawaian.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const exportPDF = async (query: string): Promise<any> => {
  return axios({
    url: `${ApiConfig.EXPORT_PDF_KEPEGAWAIAN_URL}?${query}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "SIMBI Data Kepegawaian.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export {
  getDetailKepegawaian,
  postKepegawaian,
  deleteKepegawaian,
  downloadDocKepegawaian,
  exportExcel,
  exportPDF,
};
