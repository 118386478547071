import { ShowLimitDT } from "../../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../../hope-ui/components/table/SearchInput";
import { useQueryRequest } from "../../../../provider/query-request/data-gtk";
import { useQueryResponse } from "../../../../provider/query-response/kepegawaian";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

const Toolbar = () => {
  const { state, updateState } = useQueryRequest();
  const {
    handleExportExcel,
    isLoadingExportExcel,
    handleExportPDF,
    isLoadingExportPDF,
  } = useQueryResponse();

  return (
    <div>
      {(isLoadingExportExcel || isLoadingExportPDF) && <FetchLoading />}
      <div className="row pt-3 px-4 gy-3">
        <div className="col-12 d-flex flex-row justify-content-end gap-3">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              handleExportExcel();
            }}
          >
            <i className="fa fa-file-excel me-2"></i>
            Export Excel
          </button>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => {
              handleExportPDF();
            }}
          >
            <i className="fa fa-file-pdf me-2"></i>
            Export PDF
          </button>
        </div>

        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>

        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
