import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormUploadTandaTanganPayload,
  initialQueryResponse,
} from "../../../models/upload-tanda-tangan/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { initialQueryState } from "../../../models/global";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getDetailUploadTandaTangan,
  postUploadTandaTangan,
} from "../../../api/upload-tanda-tangan/_request";
import { useAuth } from "../../core/auth";
const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();
  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailUploadTandaTangan.nik_gtk = nik_gtk;
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [detailUploadTandaTangan, setDetailUploadTandaTangan] =
    useState<FormUploadTandaTanganPayload>(
      initialQueryResponse.detailUploadTandaTangan
    );

  const formikUploadTandaTanganValidationSchema = Yup.object().shape({
    file: Yup.string().required("Tanda Tangan Tidak Boleh Kosong"),
  });

  const formikUploadTandaTangan = useFormik<FormUploadTandaTanganPayload>({
    initialValues: initialQueryResponse.detailUploadTandaTangan,
    enableReinitialize: false,
    validationSchema: formikUploadTandaTanganValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostUploadTandaTangan(payload);
    },
  });

  const {
    mutateAsync: PostUploadTandaTangan,
    isLoading: isLoadingPostUploadTandaTangan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_UPLOAD_TANDA_TANGAN}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postUploadTandaTangan(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_UPLOAD_TANDA_TANGAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostUploadTandaTangan = useCallback(
    async (payload: any) => {
      await PostUploadTandaTangan({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              handleGetDetailUploadTandaTangan(nik_gtk);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostUploadTandaTangan]
  );

  const {
    mutateAsync: GetDetailUploadTandaTangan,
    isLoading: isLoadingGetDetailUploadTandaTangan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_UPLOAD_TANDA_TANGAN}`,
    mutationFn: ({ id }: { id: string }) => getDetailUploadTandaTangan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_UPLOAD_TANDA_TANGAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailUploadTandaTangan = useCallback(
    async (id: string) => {
      await GetDetailUploadTandaTangan({ id })
        .then((res) => {
          setDetailUploadTandaTangan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailUploadTandaTangan]
  );

  const value = useMemo(
    () => ({
      formikUploadTandaTangan,
      formikUploadTandaTanganValidationSchema,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      handlePostUploadTandaTangan,
      isLoadingPostUploadTandaTangan,
      handleGetDetailUploadTandaTangan,
      isLoadingGetDetailUploadTandaTangan,
      detailUploadTandaTangan,
      setDetailUploadTandaTangan,
    }),
    [
      formikUploadTandaTangan,
      formikUploadTandaTanganValidationSchema,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      handlePostUploadTandaTangan,
      isLoadingPostUploadTandaTangan,
      handleGetDetailUploadTandaTangan,
      isLoadingGetDetailUploadTandaTangan,
      detailUploadTandaTangan,
      setDetailUploadTandaTangan,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
