import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormSpeakingFocusPayload,
  initialQueryResponse,
} from "../../../models/speaking-focus/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/speaking-focus";
import {
  deleteSpeakingFocus,
  getDetailSpeakingFocus,
  getListSpeakingFocus,
  postSpeakingFocus,
} from "../../../api/speaking-focus/_request";
import { initialQueryState } from "../../../models/global";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nama"]`;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const [detailSpeakingFocus, setDetailSpeakingFocus] =
    useState<FormSpeakingFocusPayload>(
      initialQueryResponse.detailSpeakingFocus
    );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikSpeakingFocus.setValues(initialQueryResponse.detailSpeakingFocus);
      setDetailSpeakingFocus(initialQueryResponse.detailSpeakingFocus);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_SPEAKING_FOCUS}-${query}`,
    () => getListSpeakingFocus(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikSpeakingFocusValidationSchema = Yup.object().shape({
    nama: Yup.string().required("Speaking Focus Tidak Boleh Kosong"),
  });

  const formikSpeakingFocus = useFormik<FormSpeakingFocusPayload>({
    initialValues: initialQueryResponse.detailSpeakingFocus,
    enableReinitialize: false,
    validationSchema: formikSpeakingFocusValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostSpeakingFocus(payload);
    },
  });

  const {
    mutateAsync: PostSpeakingFocus,
    isLoading: isLoadingPostSpeakingFocus,
  } = useMutation({
    mutationKey: `${QUERIES.POST_SPEAKING_FOCUS}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postSpeakingFocus(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_SPEAKING_FOCUS}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostSpeakingFocus = useCallback(
    async (payload: any) => {
      await PostSpeakingFocus({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModal(false);
                setResetForm(true);
                refetchListData();
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostSpeakingFocus]
  );

  const {
    mutateAsync: GetDetailSpeakingFocus,
    isLoading: isLoadingGetDetailSpeakingFocus,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_SPEAKING_FOCUS}`,
    mutationFn: ({ id }: { id: string }) => getDetailSpeakingFocus(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_SPEAKING_FOCUS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailSpeakingFocus = useCallback(
    async (id: string) => {
      await GetDetailSpeakingFocus({ id })
        .then((res) => {
          setDetailSpeakingFocus(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailSpeakingFocus]
  );

  const {
    mutateAsync: DeleteSpeakingFocus,
    isLoading: isLoadingDeleteSpeakingFocus,
  } = useMutation({
    mutationKey: `${QUERIES.DELETE_SPEAKING_FOCUS}`,
    mutationFn: ({ id }: { id: string }) => deleteSpeakingFocus(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DELETE_SPEAKING_FOCUS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteSpeakingFocus = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteSpeakingFocus({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteSpeakingFocus]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModal,
      setShowModal,
      formikSpeakingFocus,
      formikSpeakingFocusValidationSchema,
      submitting,
      setSubmitting,
      handlePostSpeakingFocus,
      isLoadingPostSpeakingFocus,
      detailSpeakingFocus,
      setDetailSpeakingFocus,
      handleGetDetailSpeakingFocus,
      isLoadingGetDetailSpeakingFocus,
      resetForm,
      setResetForm,
      handleDeleteSpeakingFocus,
      isLoadingDeleteSpeakingFocus,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModal,
      setShowModal,
      formikSpeakingFocus,
      formikSpeakingFocusValidationSchema,
      submitting,
      setSubmitting,
      handlePostSpeakingFocus,
      isLoadingPostSpeakingFocus,
      detailSpeakingFocus,
      setDetailSpeakingFocus,
      handleGetDetailSpeakingFocus,
      isLoadingGetDetailSpeakingFocus,
      resetForm,
      setResetForm,
      handleDeleteSpeakingFocus,
      isLoadingDeleteSpeakingFocus,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
