import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import Select2 from "../../../hope-ui/components/select-2";
import Datepicker from "../../../hope-ui/components/datepicker";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import { FC, useEffect, useState } from "react";
import TextareaComponent from "../../../hope-ui/components/textarea-input";
import InputRadio from "../../../hope-ui/components/input-radio";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { FormDataGTKPayload } from "../../../models/data-gtk/_queryResponse";
import moment from "moment";
import {
  QueryResponseProvider as QueryResponseProviderDataGTKEntry,
  useQueryResponse as useQueryResponseDataGTKEntry,
} from "../../../provider/query-response/data-gtk/entry";

import {
  QueryResponseProvider as QueryResponseProviderMasterUnitKerja,
  useQueryResponse as useQueryResponseMasterUnitKerja,
} from "../../../provider/query-response/master-data/unit-kerja";
import {
  QueryResponseProvider as QueryResponseProviderStatusPerkawinan,
  useQueryResponse as useQueryResponseStatusPerkawinan,
} from "../../../provider/query-response/master-data/status-perkawinan";
import TextInputGroupComponent from "../../../hope-ui/components/text-input-group";
import { useParams } from "react-router-dom";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../provider/query-response/detail-gtk";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";

type Props = {
  activeTab?: number;
  useTab?: boolean;
};

const FormEntry: FC<Props> = ({ activeTab, useTab }) => {
  const { nik_gtk } = useParams();

  const {
    handlePostDataGTK,
    isLoadingPostDataGTK,
    handleGetDetailGTK,
    isLoadingGetDetailGTK,
    detailGTK,
    setUseTab,
  } = useQueryResponseDataGTKEntry();

  const { nikGTK } = useQueryResponseDetailGTK();

  useEffect(() => {
    if (detailGTK) {
      if (!detailGTK.data.tanggal_lahir) {
        detailGTK.data.tanggal_lahir = dayjs().format("YYYY-MM-DD");
      }
      if (!detailGTK.data.status_perkawinan) {
        detailGTK.data.status_perkawinan = "";
      }
      if (!detailGTK.data.domisili_gtk) {
        detailGTK.data.domisili_gtk = {
          id: null,
          nik_gtk: "",
          alamat_domisili: "",
          rt_domisili: "",
          rw_domisili: "",
          nama_dusun_kota_domisili: "",
          kelurahan_domisili: "",
          kecamatan_domisili: "",
          kode_pos_domisili: "",
        };
      }
      formikGTK.setValues(detailGTK.data);
    }
  }, [detailGTK]);

  useEffect(() => {
    if (handleGetDetailGTK && nik_gtk) handleGetDetailGTK(nik_gtk);
    else if (handleGetDetailGTK && nikGTK) {
      handleGetDetailGTK(nikGTK);
    }
  }, [handleGetDetailGTK, nik_gtk, nikGTK]);

  const listDataMasterUnitKerja = useQueryResponseMasterUnitKerja().listData;
  const isLoadingFetchDataMasterUnitKerja =
    useQueryResponseMasterUnitKerja().isLoadingFetchData;

  let optionsMasterUnitKerja: any = [];
  listDataMasterUnitKerja?.data?.map((val, idx) => {
    optionsMasterUnitKerja.push({
      value: val.name,
      label: val.name,
    });
  });

  const listDataStatusPerkawinan = useQueryResponseStatusPerkawinan().listData;
  const isLoadingFetchDataStatusPerkawinan =
    useQueryResponseStatusPerkawinan().isLoadingFetchData;

  let optionsStatusPerkawinan: any = [];
  listDataStatusPerkawinan?.data?.map((val, idx) => {
    optionsStatusPerkawinan.push({
      value: val.name,
      label: val.name,
    });
  });

  let optionsWargaNegara: any = [
    {
      value: "WNI",
      label: "WNI",
    },
    {
      value: "WNA",
      label: "WNA",
    },
  ];

  let optionsGolonganDarah: any = [
    {
      value: "A+",
      label: "A+",
    },
    {
      value: "A-",
      label: "A-",
    },
    {
      value: "B+",
      label: "B+",
    },
    {
      value: "B-",
      label: "B-",
    },
    {
      value: "AB+",
      label: "AB+",
    },
    {
      value: "AB-",
      label: "AB-",
    },
    {
      value: "O+",
      label: "O+",
    },
    {
      value: "O-",
      label: "O-",
    },
  ];
  const [statusPerkawinan, setStatusPerkawinan] = useState<string | null>();

  const [submitting, setSubmitting] = useState(false);
  const formikGTKValidationSchema = Yup.object().shape({
    nik_gtk: Yup.string().required("NIK Tidak Boleh Kosong"),
    nama_gtk: Yup.string().required("Nama Lengkap Tidak Boleh Kosong"),
    unit_kerja: Yup.string().required("Unit Kerja Tidak Boleh Kosong"),
    jenis_kelamin: Yup.string().required("Jenis Kelamin Tidak Boleh Kosong"),
    email: Yup.string().required("Email Tidak Boleh Kosong"),
    tempat_lahir: Yup.string().required("Tempat Lahir Tidak Boleh Kosong"),
    tanggal_lahir: Yup.string().required("Tanggal Lahir Tidak Boleh Kosong"),
    nomor_hp: Yup.string().required("No. Handphone Tidak Boleh Kosong"),
    nama_ibu_kandung: Yup.string().required(
      "Nama Ibu Kandung Tidak Boleh Kosong"
    ),
    npwp: Yup.string().required("NPWP Tidak Boleh Kosong"),
    nama_wajib_pajak: Yup.string().required(
      "Nama Wajib Pajak Tidak Boleh Kosong"
    ),
    warga_negara: Yup.string().required("Kewarganegaraan Tidak Boleh Kosong"),
    status_perkawinan: Yup.string().required(
      "Status Perkawinan Tidak Boleh Kosong"
    ),
    nama_pasangan_hidup:
      statusPerkawinan === "Menikah"
        ? Yup.string().required("Nama Suami / Istri Tidak Boleh Kosong")
        : Yup.string().nullable(),
    pekerjaan_pasangan_hidup:
      statusPerkawinan === "Menikah"
        ? Yup.string().required("Pekerjaan Suami / Istri Tidak Boleh Kosong")
        : Yup.string().nullable(),
    jumlah_penghasilan_suami:
      statusPerkawinan === "Menikah"
        ? Yup.string().required("Penghasilan Suami / Istri Tidak Boleh Kosong")
        : Yup.string().nullable(),
    no_bpjs_kesehatan: Yup.string().required(
      "No. BPJS Kesehatan Tidak Boleh Kosong"
    ),
    no_bpjs_ketenagakerjaan: Yup.string().required(
      "No. BPJS Ketenagakerjaan Tidak Boleh Kosong"
    ),
    alamat: Yup.string().required("Alamat Tidak Boleh Kosong"),
    rt: Yup.string().required("RT Tidak Boleh Kosong"),
    rw: Yup.string().required("RW Tidak Boleh Kosong"),
    nama_dusun_kota: Yup.string().required("Dusun / Kota Tidak Boleh Kosong"),
    kelurahan: Yup.string().required("Desa / Kelurahan Tidak Boleh Kosong"),
    kecamatan: Yup.string().required("Kecamatan Tidak Boleh Kosong"),
    kode_pos: Yup.string().required("Kode Pos Tidak Boleh Kosong"),
    domisili_gtk: Yup.object().shape({
      alamat_domisili: Yup.string().required("Alamat Tidak Boleh Kosong"),
      rt_domisili: Yup.string().required("RT Tidak Boleh Kosong"),
      rw_domisili: Yup.string().required("RW Tidak Boleh Kosong"),
      nama_dusun_kota_domisili: Yup.string().required(
        "Dusun / Kota Tidak Boleh Kosong"
      ),
      kelurahan_domisili: Yup.string().required(
        "Desa / Kelurahan Tidak Boleh Kosong"
      ),
      kecamatan_domisili: Yup.string().required("Kecamatan Tidak Boleh Kosong"),
      kode_pos_domisili: Yup.string().required("Kode Pos Tidak Boleh Kosong"),
    }),
    id_bank: Yup.string().required("Nama Bank Tidak Boleh Kosong"),
    rekening_atas_nama: Yup.string().required(
      "Nama Pemilik Rekening Tidak Boleh Kosong"
    ),
    no_rekening_bank: Yup.string().required("No. Rekening Tidak Boleh Kosong"),
    tinggi_badan: Yup.number().required("Tinggi Badan Tidak Boleh Kosong"),
    berat_badan: Yup.number().required("Berat Badan Tidak Boleh Kosong"),
    golongan_darah: Yup.string().required("Golongan Darah Tidak Boleh Kosong"),
    jenis_gtk: Yup.string().required("Jenis GTK Tidak Boleh Kosong"),
  });

  const formikGTK = useFormik<FormDataGTKPayload>({
    initialValues: {
      id: null,
      nik_gtk: null,
      nama_gtk: null,
      unit_kerja: null,
      email: null,
      jenis_kelamin: null,
      tempat_lahir: null,
      tanggal_lahir: null,
      nomor_hp: null,
      nama_ibu_kandung: null,
      alamat: null,
      rt: null,
      rw: null,
      nama_dusun_kota: null,
      kelurahan: null,
      kecamatan: null,
      kode_pos: null,
      npwp: null,
      nama_wajib_pajak: null,
      warga_negara: null,
      status_perkawinan: null,
      nama_pasangan_hidup: null,
      pekerjaan_pasangan_hidup: null,
      jumlah_penghasilan_suami: null,
      no_bpjs_kesehatan: null,
      no_bpjs_ketenagakerjaan: null,
      rekening_atas_nama: null,
      id_bank: null,
      no_rekening_bank: null,
      rekening_atas_nama_non_gaji: null,
      id_bank_non_gaji: null,
      no_rekening_bank_non_gaji: null,
      domisili_gtk: {
        id: null,
        nik_gtk: null,
        alamat_domisili: null,
        rt_domisili: null,
        rw_domisili: null,
        nama_dusun_kota_domisili: null,
        kelurahan_domisili: null,
        kecamatan_domisili: null,
        kode_pos_domisili: null,
      },
      tinggi_badan: null,
      berat_badan: null,
      golongan_darah: null,
      jenis_gtk: null,
    },
    validationSchema: formikGTKValidationSchema,
    enableReinitialize: false,
    onSubmit: (values: any) => {
      console.log(values);
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        let value = values[key];
        if (key === "domisili_gtk") {
          payload.append(key, JSON.stringify(value));
        } else {
          if (value) payload.append(key, value);
        }
      });

      if (handlePostDataGTK) handlePostDataGTK(payload);
    },
  });

  useEffect(() => {
    if (detailGTK) {
      setStatusPerkawinan(detailGTK.data.status_perkawinan);
      formikGTK.setValues({
        id: detailGTK ? detailGTK?.data?.id : null,
        nik_gtk: detailGTK ? detailGTK?.data?.nik_gtk : null,
        nama_gtk: detailGTK ? detailGTK?.data?.nama_gtk : null,
        unit_kerja: detailGTK ? detailGTK?.data?.unit_kerja : null,
        email: detailGTK ? detailGTK?.data?.email : null,
        jenis_kelamin: detailGTK ? detailGTK?.data?.jenis_kelamin : null,
        tempat_lahir: detailGTK ? detailGTK?.data?.tempat_lahir : null,
        tanggal_lahir:
          detailGTK && detailGTK.data && detailGTK.data.tanggal_lahir
            ? dayjs(detailGTK?.data?.tanggal_lahir).format("YYYY-MM-DD")
            : null,
        nomor_hp: detailGTK ? detailGTK?.data?.nomor_hp : null,
        nama_ibu_kandung: detailGTK ? detailGTK?.data?.nama_ibu_kandung : null,
        alamat: detailGTK ? detailGTK?.data?.alamat : null,
        rt: detailGTK ? detailGTK?.data?.rt : null,
        rw: detailGTK ? detailGTK?.data?.rw : null,
        nama_dusun_kota: detailGTK ? detailGTK?.data?.nama_dusun_kota : null,
        kelurahan: detailGTK ? detailGTK?.data?.kelurahan : null,
        kecamatan: detailGTK ? detailGTK?.data?.kecamatan : null,
        kode_pos: detailGTK ? detailGTK?.data?.kode_pos : null,
        npwp: detailGTK ? detailGTK?.data?.npwp : null,
        nama_wajib_pajak: detailGTK ? detailGTK?.data?.nama_wajib_pajak : null,
        warga_negara: detailGTK ? detailGTK?.data?.warga_negara : null,
        status_perkawinan: detailGTK
          ? detailGTK?.data?.status_perkawinan
          : null,
        nama_pasangan_hidup: detailGTK
          ? detailGTK?.data?.nama_pasangan_hidup
          : null,
        pekerjaan_pasangan_hidup: detailGTK
          ? detailGTK?.data?.pekerjaan_pasangan_hidup
          : null,
        jumlah_penghasilan_suami: detailGTK
          ? detailGTK?.data?.jumlah_penghasilan_suami
          : null,
        no_bpjs_kesehatan: detailGTK
          ? detailGTK?.data?.no_bpjs_kesehatan
          : null,
        no_bpjs_ketenagakerjaan: detailGTK
          ? detailGTK?.data?.no_bpjs_ketenagakerjaan
          : null,
        rekening_atas_nama: detailGTK
          ? detailGTK?.data?.rekening_atas_nama
          : null,
        id_bank: detailGTK ? detailGTK?.data?.id_bank : null,
        no_rekening_bank: detailGTK ? detailGTK?.data?.no_rekening_bank : null,
        rekening_atas_nama_non_gaji: detailGTK
          ? detailGTK?.data?.rekening_atas_nama_non_gaji
          : null,
        id_bank_non_gaji: detailGTK ? detailGTK?.data?.id_bank_non_gaji : null,
        no_rekening_bank_non_gaji: detailGTK
          ? detailGTK?.data?.no_rekening_bank_non_gaji
          : null,
        domisili_gtk: {
          id: detailGTK ? detailGTK?.data?.domisili_gtk?.id : null,
          nik_gtk: detailGTK ? detailGTK?.data?.domisili_gtk?.nik_gtk : null,
          alamat_domisili: detailGTK
            ? detailGTK?.data?.domisili_gtk?.alamat_domisili
            : null,
          rt_domisili: detailGTK
            ? detailGTK?.data?.domisili_gtk?.rt_domisili
            : null,
          rw_domisili: detailGTK
            ? detailGTK?.data?.domisili_gtk?.rw_domisili
            : null,
          nama_dusun_kota_domisili: detailGTK
            ? detailGTK?.data?.domisili_gtk?.nama_dusun_kota_domisili
            : null,
          kelurahan_domisili: detailGTK
            ? detailGTK?.data?.domisili_gtk?.kelurahan_domisili
            : null,
          kecamatan_domisili: detailGTK
            ? detailGTK?.data?.domisili_gtk?.kecamatan_domisili
            : null,
          kode_pos_domisili: detailGTK
            ? detailGTK?.data?.domisili_gtk?.kode_pos_domisili
            : null,
        },
        tinggi_badan: detailGTK ? detailGTK?.data?.tinggi_badan : null,
        berat_badan: detailGTK ? detailGTK?.data?.berat_badan : null,
        golongan_darah: detailGTK ? detailGTK?.data?.golongan_darah : null,
        jenis_gtk: detailGTK ? detailGTK?.data?.jenis_gtk : null,
      });
    }
  }, [detailGTK]);

  const validasi_biodata: Array<any> =
    detailGTK?.data.validasi_gtk.biodata.data;
  const field_ktp = [
    "alamat",
    "rt",
    "rw",
    "nama_dusun_kota",
    "kelurahan",
    "kecamatan",
    "kode_pos",
  ];

  const field_rekening_bank = [
    "id_bank",
    "rekening_atas_nama",
    "no_rekening_bank",
  ];

  const optionsJenisGTK = [
    {
      value: "Struktur",
      label: "Struktur",
    },
    {
      value: "Non Struktur",
      label: "Non Struktur",
    },
  ];

  return (
    <Form
      style={{
        display: activeTab === 1 ? "block" : "none",
      }}
      onSubmit={(e) => {
        formikGTK.submitForm();
        setSubmitting(true);

        e.preventDefault();
      }}
      className="mb-0"
    >
      {(isLoadingGetDetailGTK || isLoadingPostDataGTK) && <FetchLoading />}

      <Card.Body className={useTab ? "border" : ""}>
        <Row className="gy-4">
          <Col md="6">
            <Form.Group>
              <NumberInputComponent
                label="NIK"
                placeholder="Masukkan NIK"
                required
                formik={formikGTK.getFieldProps("nik_gtk")}
                disabled={nik_gtk || nikGTK ? true : false}
              />
              {submitting && formikGTK.errors.nik_gtk && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.nik_gtk}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <TextInputComponent
                label="Nama Lengkap"
                placeholder="Masukkan Nama Lengkap"
                isRequired
                formik={formikGTK.getFieldProps("nama_gtk")}
              />
              {submitting && formikGTK.errors.nama_gtk && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.nama_gtk}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Select2
                label="Jenis GTK"
                placeholder="-- Pilih Jenis GTK --"
                isRequired
                onChange={(e: any) => {
                  if (e) formikGTK.setFieldValue("jenis_gtk", e.value);
                  else formikGTK.setFieldValue("jenis_gtk", null);
                }}
                options={optionsJenisGTK}
                value={formikGTK.getFieldProps("jenis_gtk").value}
              />
              {submitting && formikGTK.errors.jenis_gtk && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.jenis_gtk}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Select2
                label="Unit Kerja"
                placeholder="-- Pilih Unit Kerja --"
                isRequired
                onChange={(e: any) => {
                  if (e) formikGTK.setFieldValue("unit_kerja", e.value);
                  else formikGTK.setFieldValue("unit_kerja", null);
                }}
                isLoading={isLoadingFetchDataMasterUnitKerja}
                options={optionsMasterUnitKerja}
                value={formikGTK.getFieldProps("unit_kerja").value}
              />
              {submitting && formikGTK.errors.unit_kerja && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.unit_kerja}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <TextInputComponent
                label="Email"
                placeholder="Masukkan Email"
                formik={formikGTK.getFieldProps("email")}
                isRequired
              />
              {submitting && formikGTK.errors.email && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.email}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <InputRadio
                label="Jenis Kelamin"
                data={[
                  {
                    id: "jk_Laki-laki",
                    label: "Laki-laki",
                    value: "Laki-laki",
                  },
                  {
                    id: "jk_Perempuan",
                    label: "Perempuan",
                    value: "Perempuan",
                  },
                ]}
                required
                value={formikGTK.getFieldProps("jenis_kelamin").value}
                onChange={(e) => {
                  const value = e.target.value;
                  formikGTK.setFieldValue("jenis_kelamin", value);
                }}
              />
              {submitting && formikGTK.errors.jenis_kelamin && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.jenis_kelamin}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <TextInputComponent
                label="Tempat Lahir"
                placeholder="Masukkan Tempat Lahir"
                formik={formikGTK.getFieldProps("tempat_lahir")}
                isRequired
              />
              {submitting && formikGTK.errors.tempat_lahir && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.tempat_lahir}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Datepicker
                label="Tanggal Lahir"
                value={
                  formikGTK.getFieldProps("tanggal_lahir").value
                    ? dayjs(
                        formikGTK.getFieldProps("tanggal_lahir").value
                      ).format("YYYY-MM-DD")
                    : null
                }
                onChange={(e: any) => {
                  const value = e ? dayjs(e).format("YYYY-MM-DD") : null;
                  formikGTK.setFieldValue("tanggal_lahir", value);
                }}
                required
              />
              {submitting && formikGTK.errors.tanggal_lahir && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.tanggal_lahir}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <NumberInputComponent
                label="Tinggi Badan (Cm)"
                placeholder="Masukkan Tinggi Badan (Cm)"
                formik={formikGTK.getFieldProps("tinggi_badan")}
                required
              />
              {submitting && formikGTK.errors.tinggi_badan && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.tinggi_badan}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <NumberInputComponent
                label="Berat Badan (Kg)"
                placeholder="Masukkan Berat Badan (Kg)"
                formik={formikGTK.getFieldProps("berat_badan")}
                required
              />
              {submitting && formikGTK.errors.berat_badan && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.berat_badan}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Select2
                label="Golongan Darah"
                placeholder="-- Pilih Golongan Darah --"
                onChange={(e: any) => {
                  const value = e?.value;
                  formikGTK.setFieldValue("golongan_darah", value);
                }}
                options={optionsGolonganDarah}
                value={formikGTK.getFieldProps("golongan_darah").value ?? null}
                isRequired
              />
              {submitting && formikGTK.errors.golongan_darah && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.golongan_darah}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <NumberInputComponent
                label="No. Handphone"
                placeholder="Masukkan No. Handphone"
                formik={formikGTK.getFieldProps("nomor_hp")}
                required
              />
              {submitting && formikGTK.errors.nomor_hp && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.nomor_hp}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <TextInputComponent
                label="Nama Ibu Kandung"
                placeholder="Masukkan Nama Ibu Kandung"
                formik={formikGTK.getFieldProps("nama_ibu_kandung")}
                isRequired
              />
              {submitting && formikGTK.errors.nama_ibu_kandung && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.nama_ibu_kandung}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <NumberInputComponent
                label="NPWP"
                placeholder="Masukkan NPWP"
                formik={formikGTK.getFieldProps("npwp")}
                required
              />
              {submitting && formikGTK.errors.npwp && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.npwp}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <TextInputComponent
                label="Nama Wajib Pajak"
                placeholder="Masukkan Nama Wajib Pajak"
                formik={formikGTK.getFieldProps("nama_wajib_pajak")}
                isRequired
              />
              {submitting && formikGTK.errors.nama_wajib_pajak && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.nama_wajib_pajak}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Select2
                label="Kewarganegaraan"
                placeholder="-- Pilih Kewarganegaraan --"
                onChange={(e: any) => {
                  if (e) formikGTK.setFieldValue("warga_negara", e.value);
                  else formikGTK.setFieldValue("warga_negara", null);
                }}
                options={optionsWargaNegara}
                value={formikGTK.getFieldProps("warga_negara").value ?? null}
                isRequired
              />
              {submitting && formikGTK.errors.warga_negara && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.warga_negara}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Select2
                label="Status Perkawinan"
                placeholder="-- Pilih Status Perkawinan --"
                onChange={(e: any) => {
                  const value = e?.value;
                  formikGTK.setFieldValue("status_perkawinan", value);
                  setStatusPerkawinan(value);
                }}
                isLoading={isLoadingFetchDataStatusPerkawinan}
                options={optionsStatusPerkawinan}
                value={formikGTK.getFieldProps("status_perkawinan").value}
                isRequired
              />
              {submitting && formikGTK.errors.status_perkawinan && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.status_perkawinan}
                </div>
              )}
            </Form.Group>
          </Col>

          {/* {formikGTK.getFieldProps("status_perkawinan").value === "Menikah" && (
            <> */}
          <Col md="6">
            <Form.Group>
              <TextInputComponent
                label="Nama Suami / Istri"
                placeholder="Masukkan Nama Suami / Istri"
                formik={formikGTK.getFieldProps("nama_pasangan_hidup")}
                isRequired={statusPerkawinan === "Menikah"}
              />
              {submitting &&
                statusPerkawinan === "Menikah" &&
                formikGTK.errors.nama_pasangan_hidup && (
                  <div className="fs-6 text-danger mt-2">
                    {formikGTK.errors.nama_pasangan_hidup}
                  </div>
                )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <TextInputComponent
                label="Pekerjaan Suami / Istri"
                placeholder="Masukkan Pekerjaan Suami / Istri"
                formik={formikGTK.getFieldProps("pekerjaan_pasangan_hidup")}
                isRequired={statusPerkawinan === "Menikah"}
              />
              {submitting &&
                statusPerkawinan === "Menikah" &&
                formikGTK.errors.pekerjaan_pasangan_hidup && (
                  <div className="fs-6 text-danger mt-2">
                    {formikGTK.errors.pekerjaan_pasangan_hidup}
                  </div>
                )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <TextInputGroupComponent
                label="Penghasilan Suami / Istri"
                placeholder="Masukkan Penghasilan Suami / Istri"
                formik={formikGTK.getFieldProps("jumlah_penghasilan_suami")}
                isPrefix
                prefixText={`Rp`}
                isRequired={statusPerkawinan === "Menikah"}
              />
              {submitting &&
                statusPerkawinan === "Menikah" &&
                formikGTK.errors.jumlah_penghasilan_suami && (
                  <div className="fs-6 text-danger mt-2">
                    {formikGTK.errors.jumlah_penghasilan_suami}
                  </div>
                )}
            </Form.Group>
          </Col>
          {/* 
            </>)} */}

          <Col md="6">
            <Form.Group>
              <NumberInputComponent
                label="No. BPJS Kesehatan"
                placeholder="Masukkan No. BPJS Kesehatan"
                formik={formikGTK.getFieldProps("no_bpjs_kesehatan")}
                required
              />
              {submitting && formikGTK.errors.no_bpjs_kesehatan && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.no_bpjs_kesehatan}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <NumberInputComponent
                label="No. BPJS Ketenagakerjaan"
                placeholder="Masukkan No. BPJS Ketenagakerjaan"
                formik={formikGTK.getFieldProps("no_bpjs_ketenagakerjaan")}
                required
              />
              {submitting && formikGTK.errors.no_bpjs_ketenagakerjaan && (
                <div className="fs-6 text-danger mt-2">
                  {formikGTK.errors.no_bpjs_ketenagakerjaan}
                </div>
              )}
            </Form.Group>
          </Col>

          <Accordion className="mt-4">
            <Accordion.Item eventKey="0" className="mb-2">
              <Accordion.Header>
                Alamat KTP{" "}
                {validasi_biodata &&
                  validasi_biodata.some((value) =>
                    field_ktp.includes(value)
                  ) && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
              </Accordion.Header>
              <Accordion.Body className="bg-white">
                <Row className="gy-3">
                  <Col md="12">
                    <Form.Group>
                      <TextareaComponent
                        label="Alamat"
                        placeholder="Masukkan Alamat"
                        formik={formikGTK.getFieldProps("alamat")}
                        value={formikGTK.getFieldProps("alamat").value}
                        required
                      />
                      {submitting && formikGTK.errors.alamat && (
                        <div className="fs-6 text-danger mt-2">
                          {formikGTK.errors.alamat}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <TextInputComponent
                        label="Dusun / Kota"
                        placeholder="Masukkan Dusun / Kota"
                        formik={formikGTK.getFieldProps("nama_dusun_kota")}
                        isRequired
                      />
                      {submitting && formikGTK.errors.nama_dusun_kota && (
                        <div className="fs-6 text-danger mt-2">
                          {formikGTK.errors.nama_dusun_kota}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <TextInputComponent
                        label="Kecamatan"
                        placeholder="Masukkan Kecamatan"
                        formik={formikGTK.getFieldProps("kecamatan")}
                        isRequired
                      />
                      {submitting && formikGTK.errors.kecamatan && (
                        <div className="fs-6 text-danger mt-2">
                          {formikGTK.errors.kecamatan}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md="5">
                    <Form.Group>
                      <TextInputComponent
                        label="Desa / Kelurahan"
                        placeholder="Masukkan Desa / Kelurahan"
                        formik={formikGTK.getFieldProps("kelurahan")}
                        isRequired
                      />
                      {submitting && formikGTK.errors.kelurahan && (
                        <div className="fs-6 text-danger mt-2">
                          {formikGTK.errors.kelurahan}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md="2">
                    <Form.Group>
                      <NumberInputComponent
                        label="RT"
                        placeholder="Masukkan RT"
                        formik={formikGTK.getFieldProps("rt")}
                      />
                      {submitting && formikGTK.errors.rt && (
                        <div className="fs-6 text-danger mt-2">
                          {formikGTK.errors.rt}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md="2">
                    <Form.Group>
                      <NumberInputComponent
                        label="RW"
                        placeholder="Masukkan RW"
                        formik={formikGTK.getFieldProps("rw")}
                        required
                      />
                      {submitting && formikGTK.errors.rw && (
                        <div className="fs-6 text-danger mt-2">
                          {formikGTK.errors.rw}
                        </div>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md="3">
                    <Form.Group>
                      <TextInputComponent
                        label="Kode Pos"
                        placeholder="Masukkan Kode Pos"
                        formik={formikGTK.getFieldProps("kode_pos")}
                        isRequired
                      />
                      {submitting && formikGTK.errors.kode_pos && (
                        <div className="fs-6 text-danger mt-2">
                          {formikGTK.errors.kode_pos}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="mb-2">
              <Accordion.Header>
                Alamat Domisili{" "}
                {detailGTK && !detailGTK?.data.validasi_gtk.domisili_gtk && (
                  <Badge className="ms-2" bg="danger">
                    !
                  </Badge>
                )}
              </Accordion.Header>
              <Accordion.Body className="bg-white">
                <Row className="gy-3">
                  <Col md="12">
                    <Form.Group>
                      <TextareaComponent
                        label="Alamat"
                        placeholder="Masukkan Alamat"
                        formik={formikGTK.getFieldProps(
                          "domisili_gtk.alamat_domisili"
                        )}
                        value={
                          formikGTK.getFieldProps(
                            "domisili_gtk.alamat_domisili"
                          ).value
                        }
                        required
                      />
                      {submitting &&
                        formikGTK.errors.domisili_gtk &&
                        formikGTK.errors.domisili_gtk["alamat_domisili"] && (
                          <div className="fs-6 text-danger mt-2">
                            {formikGTK.errors.domisili_gtk["alamat_domisili"]}
                          </div>
                        )}
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <TextInputComponent
                        label="Dusun / Kota"
                        placeholder="Masukkan Dusun / Kota"
                        formik={formikGTK.getFieldProps(
                          "domisili_gtk.nama_dusun_kota_domisili"
                        )}
                        isRequired
                      />
                      {submitting &&
                        formikGTK.errors.domisili_gtk &&
                        formikGTK.errors.domisili_gtk[
                          "nama_dusun_kota_domisili"
                        ] && (
                          <div className="fs-6 text-danger mt-2">
                            {
                              formikGTK.errors.domisili_gtk[
                                "nama_dusun_kota_domisili"
                              ]
                            }
                          </div>
                        )}
                    </Form.Group>
                  </Col>

                  <Col md="6">
                    <Form.Group>
                      <TextInputComponent
                        label="Kecamatan"
                        placeholder="Masukkan Kecamatan"
                        formik={formikGTK.getFieldProps(
                          "domisili_gtk.kecamatan_domisili"
                        )}
                        isRequired
                      />
                      {submitting &&
                        formikGTK.errors.domisili_gtk &&
                        formikGTK.errors.domisili_gtk["kecamatan_domisili"] && (
                          <div className="fs-6 text-danger mt-2">
                            {
                              formikGTK.errors.domisili_gtk[
                                "kecamatan_domisili"
                              ]
                            }
                          </div>
                        )}
                    </Form.Group>
                  </Col>

                  <Col md="5">
                    <Form.Group>
                      <TextInputComponent
                        label="Desa / Kelurahan"
                        placeholder="Masukkan Desa / Kelurahan"
                        formik={formikGTK.getFieldProps(
                          "domisili_gtk.kelurahan_domisili"
                        )}
                        isRequired
                      />
                      {submitting &&
                        formikGTK.errors.domisili_gtk &&
                        formikGTK.errors.domisili_gtk["kelurahan_domisili"] && (
                          <div className="fs-6 text-danger mt-2">
                            {
                              formikGTK.errors.domisili_gtk[
                                "kelurahan_domisili"
                              ]
                            }
                          </div>
                        )}
                    </Form.Group>
                  </Col>

                  <Col md="2">
                    <Form.Group>
                      <NumberInputComponent
                        label="RT"
                        placeholder="Masukkan RT"
                        formik={formikGTK.getFieldProps(
                          "domisili_gtk.rt_domisili"
                        )}
                        required
                      />
                      {submitting &&
                        formikGTK.errors.domisili_gtk &&
                        formikGTK.errors.domisili_gtk["rt_domisili"] && (
                          <div className="fs-6 text-danger mt-2">
                            {formikGTK.errors.domisili_gtk["rt_domisili"]}
                          </div>
                        )}
                    </Form.Group>
                  </Col>

                  <Col md="2">
                    <Form.Group>
                      <NumberInputComponent
                        label="RW"
                        placeholder="Masukkan RW"
                        formik={formikGTK.getFieldProps(
                          "domisili_gtk.rw_domisili"
                        )}
                        required
                      />
                      {submitting &&
                        formikGTK.errors.domisili_gtk &&
                        formikGTK.errors.domisili_gtk["rw_domisili"] && (
                          <div className="fs-6 text-danger mt-2">
                            {formikGTK.errors.domisili_gtk["rw_domisili"]}
                          </div>
                        )}
                    </Form.Group>
                  </Col>

                  <Col md="3">
                    <Form.Group>
                      <TextInputComponent
                        label="Kode Pos"
                        placeholder="Masukkan Kode Pos"
                        formik={formikGTK.getFieldProps(
                          "domisili_gtk.kode_pos_domisili"
                        )}
                        isRequired
                      />
                      {submitting &&
                        formikGTK.errors.domisili_gtk &&
                        formikGTK.errors.domisili_gtk["kode_pos_domisili"] && (
                          <div className="fs-6 text-danger mt-2">
                            {formikGTK.errors.domisili_gtk["kode_pos_domisili"]}
                          </div>
                        )}
                    </Form.Group>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="mb-2">
              <Accordion.Header>
                Rekening Gaji{" "}
                {validasi_biodata &&
                  validasi_biodata.some((value) =>
                    field_rekening_bank.includes(value)
                  ) && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
              </Accordion.Header>
              <Accordion.Body className="bg-white">
                <Row className="gy-3">
                  <Col md="6">
                    <TextInputComponent
                      label="Nama Pemilik Rekening"
                      placeholder="Masukkan Nama Pemilik Rekening"
                      formik={formikGTK.getFieldProps("rekening_atas_nama")}
                      isRequired
                    />
                    {submitting && formikGTK.errors.rekening_atas_nama && (
                      <div className="fs-6 text-danger mt-2">
                        {formikGTK.errors.rekening_atas_nama}
                      </div>
                    )}
                  </Col>

                  <Col md="6">
                    <TextInputComponent
                      label="Nama Bank"
                      placeholder="Masukkan Nama Bank"
                      formik={formikGTK.getFieldProps("id_bank")}
                      isRequired
                    />
                    {submitting && formikGTK.errors.id_bank && (
                      <div className="fs-6 text-danger mt-2">
                        {formikGTK.errors.id_bank}
                      </div>
                    )}
                  </Col>

                  <Col md="6">
                    <TextInputComponent
                      label="No. Rekening"
                      placeholder="Masukkan No. Rekening"
                      formik={formikGTK.getFieldProps("no_rekening_bank")}
                      isRequired
                    />
                    {submitting && formikGTK.errors.no_rekening_bank && (
                      <div className="fs-6 text-danger mt-2">
                        {formikGTK.errors.no_rekening_bank}
                      </div>
                    )}
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="mb-2">
              <Accordion.Header>Rekening Non Gaji</Accordion.Header>
              <Accordion.Body className="bg-white">
                <Row className="gy-3">
                  <Col md="6">
                    <TextInputComponent
                      label="Nama Pemilik Rekening"
                      placeholder="Masukkan Nama Pemilik Rekening"
                      formik={formikGTK.getFieldProps(
                        "rekening_atas_nama_non_gaji"
                      )}
                    />
                  </Col>

                  <Col md="6">
                    <TextInputComponent
                      label="Nama Bank"
                      placeholder="Masukkan Nama Bank"
                      formik={formikGTK.getFieldProps("id_bank_non_gaji")}
                    />
                  </Col>

                  <Col md="6">
                    <TextInputComponent
                      label="No. Rekening"
                      placeholder="Masukkan No. Rekening"
                      formik={formikGTK.getFieldProps(
                        "no_rekening_bank_non_gaji"
                      )}
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Row>
      </Card.Body>
      <Card.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
        <Button type="submit" className="btn btn-sm btn-primary">
          <i className="far fa-floppy-disk me-2"></i>
          Simpan
        </Button>
      </Card.Footer>
    </Form>
  );
};

export const FormEntryGTKWrapper: FC<Props> = ({ activeTab, useTab }) => (
  <QueryResponseProviderDataGTKEntry>
    <QueryResponseProviderMasterUnitKerja>
      <QueryResponseProviderStatusPerkawinan>
        <FormEntry activeTab={activeTab} useTab={useTab} />
      </QueryResponseProviderStatusPerkawinan>
    </QueryResponseProviderMasterUnitKerja>
  </QueryResponseProviderDataGTKEntry>
);
