import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const postUploadTandaTangan = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_UPLOAD_TANDA_TANGAN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailUploadTandaTangan = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_UPLOAD_TANDA_TANGAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export { postUploadTandaTangan, getDetailUploadTandaTangan };
